import {apiRequest} from "@/lib/API";

export const ANALYTICS_CREATE_DOCUMENT = (key: string, data: any) => apiRequest.POST(
  `/analytics/create`,
  undefined,
  {
    key,
    data
  },
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);