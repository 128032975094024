import {apiRequest, IRequestOpts} from "@/lib/API";
import {decorateCategoryWithImg} from "@/lib/utils/categories";
//GET
export const GET_CATEGORIES_GET_ALL = (opts?: IRequestOpts) => apiRequest.GET(
  `/categories/get/all`,
  undefined,
  undefined,
  undefined,
  undefined, {
    unwrapData: true,
    cache: true,
    ...opts,
  });

export const GET_MANUFACTURERS_GET_ALL = (opts?: IRequestOpts) => apiRequest.GET(
  `/manufacturers/get/all`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    unwrapData: true,
    cache: true,
    ...opts
  }
);

// POST
export const CITIES_GET_BIGGEST = () => apiRequest.POST(
  `/settings/cities/get/biggest`,
  undefined,
  undefined,
  undefined,
  undefined,
  {cache: false}
);
export const CITIES_GET_ALL = (opts?: IRequestOpts) => apiRequest.POST(
  `/settings/cities/get/all`,
  undefined,
  undefined,
  undefined,
  undefined,
  {cache: false, ...opts}
);

export const GET_CITIES_GET_ALL = () => apiRequest.GET(
  `/cities/get/all/asTaxonomy`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    unwrapData: true,
    cache: true
  }
);
export const CATEGORIES_GET_ALL = (opts?: IRequestOpts) => apiRequest.POST(
  `/categories/get/all`,
  undefined,
  undefined,
  undefined,
  undefined, {
    cache: false,
    decorate: decorateCategoryWithImg,
    ...opts,
  });
export const MANUFACTURERS_GET_ALL = () => apiRequest.POST(
  `/manufacturers/get/all`,
  undefined,
  undefined,
  undefined,
  undefined,
  {cache: false}
);
export const MANUFACTURERS_GET_ALL_BY_CATEGORY = (
  data?: any
) => apiRequest.POST(
  `/manufacturers/get/all/by/category`,
  undefined,
  data);

export const NEWSLETTER_NEW_USER = (
  data: any
) => apiRequest.POST('/mail/newsletter/user/create', undefined, data, undefined, undefined, {
  fetchOnly: true,
});