import {apiRequest, IRequestOpts} from "@/lib/API";
import {APIInterface} from "@/lib/API.interface";
import {ISellerDataType} from "@/data/rentalioTypes";

export const UNKNOWN_TENANT_CREATE = (data: any) => apiRequest.POST(
  '/unknownTenant/create',
  undefined,
  data,
  undefined,
  undefined, {
    fetchOnly: true,
  });
export const UNKNOWN_TENANT_SEND_CONFIRMATION_CODE = (data: any) => apiRequest.POST(
  '/unknownTenant/sendConfirmationCode',
  undefined,
  data,
  undefined,
  undefined, {
    fetchOnly: true,
  });

export const UNKNOWN_TENANT_CHECK_CONFIRMATION_CODE = (data: any) => apiRequest.POST(
  '/unknownTenant/checkConfirmationCode',
  undefined,
  data,
  undefined,
  undefined, {
    fetchOnly: true,
  });
export const UNKNOWN_TENANT_REGISTER_TRANSACTION = (data: any) => apiRequest.POST(
  '/unknownTenant/registerTransaction',
  undefined,
  data,
  undefined,
  undefined, {
    fetchOnly: true,
  });