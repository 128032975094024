import {IGearDataResponse} from "@/data/rentalioTypes";
import {TaxonomyType} from "@/data/types";

export const decorateCategoryWithImg = (categories: { data: TaxonomyType[] }) => {
  categories?.data.forEach(category => {
    category.thumbnail = category.value
      ? `/public/common/categoriesnew/${category.value}.jpg`
      : `/public/common/categoriesnew/OTHER.jpg`;
  })
  return categories;
}
export const MCategoryNames: {
  [key: string]: string
} = {
  AKCESORIA: 'Akcesoria',
  ALL: 'Wszystkie',
  APARATY: 'Aparaty',
  BATERIE: 'Baterie i zasilacze',
  DRONY: 'Drony',
  DZWIEK: 'Dźwięk',
  FILTRY_OBIEKTYWOWE: 'Filtry obiektywowe',
  FOLLOW_FOCUS: 'Follow Focus',
  GLOWICE: 'Głowice',
  KAMERY: 'Kamery',
  KRANY_KAMEROWE: 'Krany kamerowe',
  MATTE_BOX: 'Matte Boxy',
  MODYFIKATORY_OSWIETLENIA: 'Modyfikatory światła',
  MONITORY: 'Monitory podglądowe',
  OBIEKTYWY: 'Obiektywy',
  OSWIETLENIE_FILMOWE: 'Oświetlenie filmowe',
  OSWIETLENIE_FOTOGRAFICZNE: 'Oświetlenie fotograficzne',
  POJAZDY: 'Pojazdy',
  SLIDERY: 'Slidery',
  SPRZET_ANALOGOWY: 'Sprzęt Analogowy (Klisza, VHS)',
  STABILIZATORY: 'Stabilizatory i gimbale',
  STACJE_MONTAZOWE: 'Stacje montażowe',
  STATYWY_KAMEROWE: 'Statywy kamerowe',
  STATYWY_OSWIETLENIOWE: 'Statywy oświetleniowe',
  SWIATLOMIERZE: 'Światłomierze',
  TRANSMISJA_PRODUKCJA: 'Transmisja i produkcja',
  WOZKI_TRANSPORTOWE: 'Wózek transportowy',
  WYPOSAZENIE_STUDIA: 'Wyposażenie studia',
  ZASILANIE: 'Zasilanie',
  SYSTEMY_BEZPRZEWODOWE: 'Systemy bezprzewodowe', // ????
}

export interface ICategoryMap {
  categoryName: string;
  categoryKey: string;
  count: number,
  active: boolean;
}

export interface ICategoryMapHelper {
  [key: string]: {
    key: string,
    count: number,
  }
}

export const categoryMapperFromGearSet = (sellerGearRes: IGearDataResponse['results']): ICategoryMap[] => {
  const sellerGearCategoriesKeys: ICategoryMapHelper = sellerGearRes?.length > 0
    ? sellerGearRes.reduce((acc: ICategoryMapHelper, gear) => {
      if (!acc[gear.category]) {
        acc[gear.category] = {
          key: gear.category,
          count: 1
        }
      } else {
        acc[gear.category].count++;
      }
      acc['ALL'].count++;
      return acc;
    }, {
      ALL: {
        count: 0,
        key: 'ALL'
      }
    })
    : {
      ALL: {
        count: 0,
        key: 'ALL'
      }
    }
  const sellerGearCategories: ICategoryMap[] = Object.keys(sellerGearCategoriesKeys)
    .map((categoryKey: string) => {
      const category = sellerGearCategoriesKeys[categoryKey];
      return {
        categoryName: MCategoryNames[category.key],
        categoryKey: category.key,
        count: category.count,
        active: category.key === 'ALL',
      }
    })
  return sellerGearCategories;
}