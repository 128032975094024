import {apiRequest} from "@/lib/API";
import {decorateCategoryWithImg} from "@/lib/utils/categories";

export const GEARS_FOR_SEO = () => apiRequest.POST(
  `/settings/seo/gears`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  })

export const SELLERS_FOR_SEO = () => apiRequest.POST(
  `/settings/seo/sellers`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  })

export const CATEGORIES_FOR_SEO = () => apiRequest.POST(
  `/categories/get/all`,
  undefined,
  {
    notEmpty: true,
    withManufacturers: true
  },
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  });

export const MANUFACTURERS_FOR_SEO = () => apiRequest.POST(
  `/manufacturers/get/all`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);

export const CITIES_FOR_SEO = () => apiRequest.POST(
  `/settings/cities/get/all`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);

export const ARTICLES_FOR_SEO = () => apiRequest.POST(
  `/articles/get/all/seo`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);

export const ARTICLES_GET_TOPICS_AND_TAGS = () => apiRequest.POST(
  `/articles/getTopicsAndTags`,
  undefined,
  undefined,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);