'use client'
import {ILoginAuthResponse} from "@/data/rentalioTypes";
import {CookieValueTypes, deleteCookie, getCookie, setCookie} from "cookies-next";

export const ctxRemoveContext = () => {
  console.log('ctxRemoveContext => ', ctxRemoveContext);
  if (typeof window !== 'undefined') {
    deleteCookie('ctx');
    deleteCookie('ctxToken');
  }
}

export const ctxSetUpdateContextUser = (user?: ILoginAuthResponse['user'] | undefined | null) => {
  if (typeof window !== 'undefined') {
    if (user) {
      setCookie('ctx', JSON.stringify(user))
    }
  }
}

export const ctxIsLogged = (log?: string): boolean => {
  const logged = ctxGetContextUser(log);
  return !!logged;
}
export const ctxGetContextUser = (log?: string): ILoginAuthResponse['user'] | null => {
  // console.log(`===== ctxGetContextUser ${log} =====`);
  if (typeof window !== 'undefined') {
    const userCtx: CookieValueTypes = getCookie('ctx');
    const ctx: ILoginAuthResponse['user'] = userCtx
      ? JSON.parse(userCtx as string)
      : null;
    return ctx;
  }

}

export const ctxGetContextToken = (): string | undefined => {
  if (typeof window !== 'undefined') {
    return getCookie('ctxToken');
  }
  return undefined;
}