import {apiRequest} from "@/lib/API";
import {TArticleContentCategory, IArticleGeneric} from "@/app/artykul/[articleName]/genericArticles.interface";

export interface IARTICLES_GET_SINGLE_REQUEST {
  articleUrl: string
}

export interface IARTICLES_GET_ALL_REQUEST {
  limit?: number
  category?: TArticleContentCategory
}

export interface IARTICLES_GET_ALL_RESPONSE {
  count: number,
  articles: IArticleGeneric[]
}

export const ARTICLES_GET_SINGLE = (data: IARTICLES_GET_SINGLE_REQUEST) => apiRequest.POST(
  `/articles/get/single`,
  undefined,
  data,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);
export const ARTICLES_GET_ALL = (data?: IARTICLES_GET_ALL_REQUEST): Promise<IARTICLES_GET_ALL_RESPONSE> => apiRequest.POST(
  `/articles/get/all`,
  undefined,
  data,
  undefined,
  undefined,
  {
    fetchOnly: true,
    unwrapData: true,
  }
);