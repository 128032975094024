import {apiRequest, IRequestOpts} from "@/lib/API";
import {IGearDataType} from "@/data/rentalioTypes";


export interface IGEARS_GET_ALL_ALGOLIA_RESPONSE {
  results: IGearDataType[],
  count: number;
  params: {
    priceMax: number;
    priceMin: number;
  }
}

export const GEARS_GET_ALL = (
  data?: any,
  opts?: IRequestOpts
) => apiRequest.POST(
  `/gears/get/all`,
  undefined,
  data,
  undefined,
  undefined,
  {unwrapData: true, loaderState: true, ...opts}
);

export const GEARS_GET_ALL_ALGOLIA = (
  data?: any,
  opts?: IRequestOpts
): Promise<IGEARS_GET_ALL_ALGOLIA_RESPONSE> => apiRequest.POST(
  `/gears/get/all/algolia`,
  undefined,
  data,
  undefined,
  undefined,
  {fetchOnly: true, loaderState: true, unwrapData: true, ...opts}
);
export const GEARS_GET_SINGLE = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/gears/get/single`,
  undefined,
  data,
  undefined,
  undefined,
  {
    unwrapData: true,
    fetchOnly: true,
    ...opts
  }
);
export const GEARS_GET_SINGLE_BY_URL = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/gears/get/single/byUrl`,
  undefined,
  data,
  undefined,
  undefined,
  {
    unwrapData: true,
    ...opts
  }
);
export const GEARS_GET_SET_PARTS = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/gears/get/setGear`,
  undefined,
  data,
  undefined,
  undefined,
  {
    unwrapData: true,
    ...opts
  }
);
export const GEARS_GET_PARENT = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/gears/get/parentGear`,
  undefined,
  data,
  undefined,
  undefined,
  {
    unwrapData: true,
    ...opts
  }
);
export const GEARS_GET_COUNT_BY_SELLER = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/gears/get/count/bySeller`,
  undefined,
  data,
  undefined,
  undefined,
  {unwrapData: true, ...opts} || {unwrapData: true}
);
export const FILES_BY_GEAR = (data?: any, opts?: IRequestOpts) => apiRequest.POST(
  `/files/by/gear`,
  undefined,
  data,
  undefined,
  undefined,
  {
    ...opts,
  });