import {v4 as uuidv4} from 'uuid';

export default function reqNanoId(mode = 'reqId') {
  if (mode === 'reqId') {
    return uuidv4().split('-')[0];
  }
  const parts = uuidv4().split('-');
  const uuid = ['WVTTighH', parts[0], 'mAdubN26', parts[1], 'rjHA', parts[2], '3UhP', parts[3], '9h4c', parts[4], '36C7'].sort().join('-');
  return uuid;
}
