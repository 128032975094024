import {ctxGetContextToken} from "./utils/ctx.service";
import reqNanoId from "./utils/reqId";
import envs from "@/app/envs";
import {
  CATEGORIES_GET_ALL,
  CITIES_GET_ALL,
  CITIES_GET_BIGGEST,
  GET_CATEGORIES_GET_ALL,
  GET_CITIES_GET_ALL,
  GET_MANUFACTURERS_GET_ALL,
  MANUFACTURERS_GET_ALL,
  MANUFACTURERS_GET_ALL_BY_CATEGORY,
  NEWSLETTER_NEW_USER
} from "@/lib/API.common";
import {
  FILES_BY_GEAR,
  GEARS_GET_ALL,
  GEARS_GET_ALL_ALGOLIA,
  GEARS_GET_COUNT_BY_SELLER,
  GEARS_GET_PARENT,
  GEARS_GET_SET_PARTS,
  GEARS_GET_SINGLE,
  GEARS_GET_SINGLE_BY_URL
} from "@/lib/API.gears";
import {
  SELLER_CREATE_GEAR,
  SELLER_GEAR_DELETE_PHOTO,
  SELLER_GEAR_GET_OWN,
  SELLER_GEAR_SET_ACTIVE,
  SELLER_GEAR_SET_MAIN_PHOTO,
  SELLER_GEAR_UPLOAD_PHOTO,
  SELLER_NOTIFICATIONS_GET,
  SELLER_NOTIFICATIONS_READ,
  SELLER_REMOVE_ACCOUNT,
  SELLER_REMOVE_GEAR,
  SELLER_TRANSACTION_CREATE,
  SELLER_TRANSACTION_RENT_CANCEL,
  SELLER_TRANSACTION_RENT_CONFIRM,
  SELLER_TRANSACTION_RENT_DONE,
  SELLER_TRANSACTION_RENT_REVIEW_OWNER,
  SELLER_TRANSACTION_RENT_REVIEW_TENANT,
  SELLER_TRANSACTIONS_GET_ALL_AS_OWNER,
  SELLER_TRANSACTIONS_GET_ALL_AS_TENANT,
  SELLER_TRANSACTIONS_GET_SINGLE,
  SELLER_TRANSACTIONS_GET_AGREEMENT_FOR_GEAR,
  SELLER_UPDATE_GEAR,
  SELLER_UPLOAD_PHOTO,
  SELLERS_CHECK_PROFILE_NAME,
  SELLERS_GET_SINGLE_BY_PROFILE_NAME,
  SELLERS_NEW_ACCOUNT_ADDRESS,
  SELLERS_REMOVE_ACCOUNT_ADDRESS,
  SELLERS_ADDRESS_SET_DEFAULT,
  SELLERS_UPDATE_ACCOUNT,
  SELLERS_UPDATE_ACCOUNT_ADDRESS,
  SELLERS_UPDATE_NEWSLETTER,
  SELLERS_UPDATE_INSURANCE, SELLER_TRANSACTION_RENT_CANCEL_REASON
} from "@/lib/API.seller";
import {
  AUTH_GET_OWN,
  AUTH_LOGOUT,
  AUTH_SEND_VERIFICATION_EMAIL,
  AUTH_VERIFY_LEVEL_BASIC,
  AUTH_VERIFY_LEVEL_PRO_DOWNLOAD_DECLARATION,
  AUTH_VERIFY_LEVEL_PRO_CHECK,
  AUTH_VERIFY_SELLER_EMAIL_BY_CODE, AUTH_LOGIN_CLASSIC
} from "@/lib/API.auth";
import {
  UNKNOWN_TENANT_CHECK_CONFIRMATION_CODE,
  UNKNOWN_TENANT_CREATE,
  UNKNOWN_TENANT_REGISTER_TRANSACTION,
  UNKNOWN_TENANT_SEND_CONFIRMATION_CODE
} from "@/lib/API.unknownTenant";
import {
  ARTICLES_FOR_SEO, ARTICLES_GET_TOPICS_AND_TAGS,
  CATEGORIES_FOR_SEO,
  CITIES_FOR_SEO,
  GEARS_FOR_SEO,
  MANUFACTURERS_FOR_SEO,
  SELLERS_FOR_SEO
} from "@/lib/API.seo";
import {ANALYTICS_CREATE_DOCUMENT} from "@/lib/API.analytics";
import {ARTICLES_GET_ALL, ARTICLES_GET_SINGLE} from "@/lib/API.articles";

const APP_API_URL = envs.APP_API_URL;

export const authHeader = {
  Authorization: 'Basic YWRtaW4tZ1c2R1VFcU1LS1lCaTFKcWE4bEY2enRrcEs0MG1vSWE6MzZDNzloNGMzVWhQcmpIQW1BZHViTjI2V1ZUVGlnaEg='
}

export interface IRequestOpts {
  unwrapData?: boolean | undefined;
  fetchOnly?: boolean | undefined;
  formData?: boolean | undefined;
  addTokenToHeader?: boolean;
  cache?: boolean;
  decorate?: any;
  loaderState?: boolean;
  caller?: string;
}

export const apiRequest = {
  GET: (
    url: string,
    callback?: any,
    data?: any,
    headers?: any,
    errCallback?: [any, any],
    opts: IRequestOpts = {
      unwrapData: false,
      fetchOnly: false,
      formData: false,
      addTokenToHeader: false,
      cache: false,
      decorate: undefined,
      loaderState: false,
      caller: undefined
    }
  ) => {
    const unwrapData = opts.unwrapData;
    const addTokenToHeader = opts.addTokenToHeader;
    const cache = opts.cache;
    const decorate = opts.decorate;
    const loaderState = opts.loaderState;
    const caller = opts.caller;

    const body: RequestInit = {
      method: 'GET',
      ...(
        data
          ? {
            body: JSON.stringify(data)
          }
          : {}
      ),
      ...(cache ? {cache: 'force-cache', revalidate: 600} : {}),
    };
    body.headers = {
      ...body.headers,
      ...(headers
        ? headers
        : {}),
      ...(addTokenToHeader
        ? {
          ...(typeof ctxGetContextToken !== "undefined" && ctxGetContextToken()
              ? {'x-access-token': (ctxGetContextToken() as string)}
              : {}
          )
        }
        : {}),
      ...authHeader,
      'X-Request-Id': reqNanoId('reqId'),
    }
    console.log(`===== ${caller} GET ${APP_API_URL}${url} =====>`, data, ' | ', body);
    // console.log('body.headers => ', body.headers);
    return fetch(
      `${APP_API_URL}${url}`,
      body,
    )
      .then(async response => {
        let jsonResponse = await response.json();
        if (unwrapData && jsonResponse.data) {
          jsonResponse = jsonResponse.data
        }
        if (decorate && typeof decorate === "function") {
          jsonResponse = await decorate(unwrapData ? jsonResponse : jsonResponse.data)
        }
        return jsonResponse;
      })
      .catch(error => {
        console.error(`${APP_API_URL}${url} ERROR`, error)
        if (errCallback) {
          errCallback[0](loaderState ? {...errCallback[1], state: 'error'} : errCallback[1])
        } else {
          if (callback && loaderState) {
            console.error(`${APP_API_URL}${url} ERROR LOADER STATE => err`)
            callback({state: 'error'})
          }
        }
      })
  },
  POST: (
    url: string,
    callback?: any,
    data?: any,
    headers?: any,
    errCallback?: [any, any],
    opts: IRequestOpts = {
      unwrapData: false,
      fetchOnly: false,
      formData: false,
      addTokenToHeader: false,
      cache: false,
      decorate: undefined,
      loaderState: false,
      caller: undefined
    }
  ) => {
    const fetchOnly = opts.fetchOnly
    const unwrapData = opts.unwrapData;
    const addTokenToHeader = opts.addTokenToHeader;
    const cache = opts.cache;
    const formData = opts.formData;
    const decorate = opts.decorate;
    const loaderState = opts.loaderState;
    const caller = opts.caller;
    // console.log(`===== ${caller} ${APP_API_URL}${url} =====>`, data);
    const body: RequestInit = {
      method: 'POST',
      ...(
        data
          ? {
            headers: {
              ...(!formData ? {'Content-Type': 'application/json'} : {}),
            }
          }
          : {}
      ),
      ...(
        data && !formData
          ? {
            body: JSON.stringify(data)
          }
          : {}
      ),
      ...(
        data && formData
          ? {
            body: data
          }
          : {}
      ),
      // ...(cache ? {cache: 'force-cache'} : {}),
      ...(cache ? {next: {revalidate: 43200}} : {}),
    };
    body.headers = {
      ...body.headers,
      ...(headers
        ? headers
        : {}),
      ...(addTokenToHeader
        ? {
          ...(typeof ctxGetContextToken !== "undefined" && ctxGetContextToken()
              ? {'x-access-token': (ctxGetContextToken() as string)}
              : {}
          )
        }
        : {}),
      ...authHeader,
      'X-Request-Id': reqNanoId('reqId'),
    }
    // console.log('body.headers => ', body.headers);
    if (fetchOnly) {
      return fetch(
        `${APP_API_URL}${url}`,
        body,
      )
        .then(async response => {
          const json = await response.json();
          if (unwrapData && json.data) {
            return json.data
          }
          return json;
        })
    } else {
      return fetch(
        `${APP_API_URL}${url}`,
        body,
      )
        .then(response => response.json())
        .then(async (json: any) => {
          // console.log(`${APP_API_URL}${url} response =>`, json.status, json.data ? json.data : json);
          if (decorate && typeof decorate === "function") {
            json = await decorate(json)
          }
          if (callback && unwrapData && json.data) {
            return callback && callback(loaderState ? {...json.data, state: 'done'} : json.data);
          }
          if (callback) {
            return callback && callback(loaderState ? {...json, state: 'done'} : json)
          }
          return unwrapData
            ? json.data
              ? json.data
              : json.data
            : json;
        })
        .catch(error => {
          console.error(`${APP_API_URL}${url} ERROR`, error)
          if (errCallback) {
            errCallback[0](loaderState ? {...errCallback[1], state: 'error'} : errCallback[1])
          } else {
            if (callback && loaderState) {
              console.error(`${APP_API_URL}${url} ERROR LOADER STATE => err`)
              callback({state: 'error'})
            }
          }
        })
    }

  }
}

export const API = {
  ARTICLES_GET_SINGLE,
  ARTICLES_GET_ALL,
  ARTICLES_FOR_SEO,
  ARTICLES_GET_TOPICS_AND_TAGS,
  GEARS_FOR_SEO,
  SELLERS_FOR_SEO,
  CATEGORIES_FOR_SEO,
  MANUFACTURERS_FOR_SEO,
  CITIES_FOR_SEO,
  ANALYTICS_CREATE_DOCUMENT,
  AUTH_LOGIN_CLASSIC,
  AUTH_LOGOUT,
  AUTH_SEND_VERIFICATION_EMAIL,
  AUTH_VERIFY_SELLER_EMAIL_BY_CODE,
  AUTH_VERIFY_LEVEL_BASIC,
  AUTH_VERIFY_LEVEL_PRO_CHECK,
  AUTH_VERIFY_LEVEL_PRO_DOWNLOAD_DECLARATION,
  CITIES_GET_BIGGEST,
  CITIES_GET_ALL,
  GET_CITIES_GET_ALL,
  GET_CATEGORIES_GET_ALL,
  CATEGORIES_GET_ALL,
  GET_MANUFACTURERS_GET_ALL,
  MANUFACTURERS_GET_ALL,
  MANUFACTURERS_GET_ALL_BY_CATEGORY,
  GEARS_GET_ALL,
  GEARS_GET_ALL_ALGOLIA,
  GEARS_GET_SINGLE,
  GEARS_GET_SINGLE_BY_URL,
  GEARS_GET_SET_PARTS,
  GEARS_GET_PARENT,
  GEARS_GET_COUNT_BY_SELLER,
  FILES_BY_GEAR,
  NEWSLETTER_NEW_USER,
  SELLERS_GET_SINGLE_BY_PROFILE_NAME,
  AUTH_GET_OWN,
  SELLERS_UPDATE_ACCOUNT,
  SELLERS_UPDATE_ACCOUNT_ADDRESS,
  SELLERS_NEW_ACCOUNT_ADDRESS,
  SELLERS_REMOVE_ACCOUNT_ADDRESS,
  SELLERS_ADDRESS_SET_DEFAULT,
  SELLER_REMOVE_ACCOUNT,
  SELLERS_UPDATE_NEWSLETTER,
  SELLERS_UPDATE_INSURANCE,
  SELLERS_CHECK_PROFILE_NAME,
  SELLER_CREATE_GEAR,
  SELLER_UPDATE_GEAR,
  SELLER_REMOVE_GEAR,
  SELLER_GEAR_GET_OWN,
  SELLER_GEAR_UPLOAD_PHOTO,
  SELLER_UPLOAD_PHOTO,
  SELLER_GEAR_DELETE_PHOTO,
  SELLER_GEAR_SET_MAIN_PHOTO,
  SELLER_GEAR_SET_ACTIVE,
  SELLER_TRANSACTIONS_GET_ALL_AS_OWNER,
  SELLER_TRANSACTIONS_GET_ALL_AS_TENANT,
  SELLER_TRANSACTIONS_GET_SINGLE,
  SELLER_TRANSACTIONS_GET_AGREEMENT_FOR_GEAR,
  SELLER_TRANSACTION_CREATE,
  SELLER_TRANSACTION_RENT_CONFIRM,
  SELLER_TRANSACTION_RENT_CANCEL,
  SELLER_TRANSACTION_RENT_CANCEL_REASON,
  SELLER_TRANSACTION_RENT_DONE,
  SELLER_TRANSACTION_RENT_REVIEW_OWNER,
  SELLER_TRANSACTION_RENT_REVIEW_TENANT,
  SELLER_NOTIFICATIONS_GET,
  SELLER_NOTIFICATIONS_READ,
  UNKNOWN_TENANT_CREATE,
  UNKNOWN_TENANT_SEND_CONFIRMATION_CODE,
  UNKNOWN_TENANT_CHECK_CONFIRMATION_CODE,
  UNKNOWN_TENANT_REGISTER_TRANSACTION
}